.header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__logo img {
    height: 50px; // Adjust the size as needed
  }

  &__nav {
    a {
      margin: 0 15px;
      text-decoration: none;
      color: #333;
      font-weight: 400;

      &:hover {
        // color: #007bff; // Hover effect color
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;

    input {
      padding: 5px 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

.header_icon {
  margin-left: 15px;
  cursor: pointer;
}

.dropdown_category {
  // border: 1px solid red;
  border-radius: 0px !important;
  cursor: pointer;
}
