input:focus {
  outline: none;
}

/* Ensure layout takes up full viewport height */
.app-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Allow the content (body) to grow */
.app-content {
  flex-grow: 1;
  background-color: white;

}

.body {
  overflow: hidden;
}
